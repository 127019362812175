<template>
  <div justify="end">
    <v-dialog v-model="isVisibleShowLot" max-width="1200px">
      <v-card elevation="0">
        <v-card-title class="pb-4">
          <span class="text-h3"
            >{{ $t("product") }}
            {{ " : " + product.name + " (" + product.code + ")" }}
          </span>
        </v-card-title>
        <v-card-text class="pa-0 grey lighten-4">
          <v-container>
            <v-row v-if="product.lots && product.lots.length > 0">
              <v-col cols="12" sm="12" md="12">
                <v-card elevation="0">
                  <v-card-title class="text-center">
                    <span>{{ $t("lots") }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t("lot_number") }}
                            </th>
                            <th class="text-left">
                              {{ $t("expiration_date") }}
                            </th>
                            <th class="text-left">
                              {{ $t("hub_name") }}
                            </th>
                            <th class="text-left">
                              {{ $t("quantities") }}
                            </th>
                            <th class="text-left">
                              {{ $t("purchase_price_ht") }}
                            </th>
                            <th class="text-left">
                              {{ $t("purchase_tva") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in product.lots" :key="item.id">
                            <td>{{ item.lot_number }}</td>
                            <td>{{ item.expiration_date }}</td>
                            <td>{{ item.hub.name }}</td>
                            <td>{{ item.qte_in_lot }}</td>
                            <td>{{ item.lot_purchase_price_ht }}</td>
                            <td>{{ item.lot_purchase_tva }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeForm()">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isLoadingProduct: "products/isLoadingProduct",
      product: "products/product",
    }),

    isVisibleShowLot: {
      get() {
        return this.$store.state.products.isVisibleShowLot;
      },
      set(value) {
        this.$store.commit("products/IS_VISIBLE_SHOW_LOT", value);
      },
    },
  },

  data() {
    return {};
  },
  methods: {
    closeForm() {
      this.$store.dispatch("products/closeForm");
    },
  },
};
</script>
